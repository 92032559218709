import React, { useState, useEffect } from "react";
import { Dialog, DialogContent, DialogTitle, IconButton, MenuItem, Select, FormControl, InputLabel } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MessageBubbleComponent from "../components/MessageBubbleComponent";
import ChatComponent from "../components/ChatComponent";
import { apiPost, apiGet } from "../api";
import "./styles/EmailGeneratorModal.css";

/**
 * EmailGeneratorModal Component
 * 
 * This modal helps in generating emails related to student performance.
 * It allows the user to select a student and provide input regarding the subject and language of the email.
 * It uses a chat interface to interact with the assistant and generate the email content.
 * 
 * @param {Object} props - Component properties
 * @param {boolean} props.open - Whether the modal is open
 * @param {function} props.onClose - Function to close the modal
 * 
 * @returns {JSX.Element} The EmailGeneratorModal component
 */
const EmailGeneratorModal = ({ open, onClose }) => {
  const [conversation, setConversation] = useState([]);
  const [inputText, setInputText] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState("");
  const [students, setStudents] = useState([]);

  // Send a message to the backend API to generate an email
  const handleSend = async () => {
    if (inputText.trim()) {
      try {
        const updatedConversation = [...conversation, { role: "user", content: [{ text: inputText }] }];
        setConversation(updatedConversation);
        setLoading(true);
        setInputText("");

        // Prepare the payload with the selected student (or null if no student is selected)
        const payload = {
          content: updatedConversation,
          studentid: selectedStudent || null
        };

        const response = await apiPost("/generate_email", payload);
        setConversation((prevConversation) => [
          ...prevConversation,
          { role: "assistant", content: [{ text: response["output"]["message"]["content"][0]["text"] }] },
        ]);
        setLoading(false);
      } catch (error) {
        console.error("Error sending message:", error);
      }
    }
  };

  // Fetch students data from the API
  const fetchStudentsData = async () => {
    try {
      const data = await apiGet("/shs_data");
      console.log("Fetched Data:", data);

      setStudents(data);

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // Fetch the student data when the component is mounted
  useEffect(() => {
    fetchStudentsData();
  }, []);

  // Handle student selection change
  const handleStudentChange = (event) => {
    setSelectedStudent(event.target.value);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md" sx={{
      '& .MuiPaper-root': { borderRadius: '20px' }, // Adjust the radius as needed
    }}>
      <DialogTitle>
        <div className="chat-modal-header">
          <h3>Email Generator</h3>

          {/* Dropdown for selecting a student */}
          <FormControl sx={{ minWidth: 120 }}>
            <InputLabel id="student-select-label">Student</InputLabel>
            <Select
              labelId="student-select-label"
              id="student-select"
              value={selectedStudent}
              label="Student"
              onChange={handleStudentChange}
              sx={{
                borderRadius: '12px',
                backgroundColor: '#fff',
                border: '1px solid #6A5ACD',
                '&:hover': { backgroundColor: '#f3f4f6' },
              }}
            >
              {/* Option to "deselect" student */}
              <MenuItem value="">None</MenuItem>

              {/* Dynamically generate menu items from the students list */}
              {students.map((student) => (
                <MenuItem key={student.STUDENTID} value={student.STUDENTID}>
                  {`${student.FIRSTNAME} ${student.LASTNAME}`} {/* Display student name */}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <IconButton onClick={onClose} className="close-button" disableFocusRipple disableRipple>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <div className="chat-container">
          {/* Initial Assistant message */}

          <MessageBubbleComponent role="assistant" content={[{
            text: `Hello! I'm happy to help you generate a professional email to communicate student performance to parents or other educators. \n\nPlease select the student you wish to generate an email about from the drop-down menu. Then include the subject of the email and the language in which you'd like to receive the email (if other than English)\n\nOnce I have this information, I'll help you craft a clear, concise, and professionally formatted email that meets your needs.`
              .replace(/\n/g, '<br />')
          }]} loading={false} />

          {/* Display conversation history */}
          {conversation.map((message, index) => (
            <MessageBubbleComponent key={index} role={message.role} content={message.content} loading={false} />
          ))}
          {/* Display loading indicator */}
          {loading && <MessageBubbleComponent role="assistant" content={""} loading={true} />}
        </div>
        <ChatComponent inputText={inputText} setInputText={setInputText} handleSend={handleSend} />
      </DialogContent>
    </Dialog >
  );
};

export default EmailGeneratorModal;
