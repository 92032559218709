import React, { useState, useRef, useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../theme';
import { apiPost } from '../api';
import '../pages/styles/Home.css';
import '../pages/styles/main.css';
import AppBarWithLogo from '../components/AppBarWithLogo';
import ChatComponent from '../components/ChatComponent';
import MessageBubbleComponent from '../components/MessageBubbleComponent';
import DefaultButton from '../components/DefaultButton';
import EmailGeneratorModal from '../modals/EmailGeneratorModal';
import HelpButton from '../components/HelpButton';
import IEPDataModal from '../modals/IEPDataModal';

function Home() {
  const [inputText, setInputText] = useState('');
  const [conversation, setConversation] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openEmailModal, setOpenEmailModal] = useState(false);
  const [openIEPModal, setOpenIEPModal] = useState(false);
  const chatContainerRef = useRef(null);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTo({
        top: chatContainerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [conversation])

  const handleSend = async () => {
    if (inputText.trim()) {
      try {
        const updatedConversation = [...conversation, { role: "user", content: [{ text: inputText }] }];
        setConversation(updatedConversation);
        setLoading(true);
        setInputText('');
        const response = await apiPost('/general_chat', { content: updatedConversation });
        setConversation(conversation => [...conversation, { role: "assistant", content: [{ text: response['output']['message']['content'][0]['text'] }] }]);
        setLoading(false);
      } catch (error) {
        console.error("Error sending message:", error);
      }
    }
  };

  const generatePrompt = async () => {
    // checks last message in the conversation array
    const lastMessage = conversation.filter(msg => msg.role === "user").slice(-1)[0] || { role: "user", content: [{ text: "" }] };

    let loadingText = "Discovering Prompts"
    setInputText(loadingText);

    let dotCount = 0;
    const intervalId = setInterval(() => {
      dotCount = (dotCount + 1) % 4; // Count up to 3 dots
      setInputText("Discovering Prompts" + ".".repeat(dotCount));
    }, 500); // Update every 500ms

    try {
      // send last message of conversation array to endpoint
      const response = await apiPost('/generate_prompt', { content: [lastMessage] })
      let generatedText = response?.output?.message?.content?.[0]?.text || "";
      generatedText = generatedText.replace(/\n\n/g, ' ').trim();

      clearInterval(intervalId); 
      setInputText(generatedText);
    }
    catch (error) {
      console.error("Error generating prompt", error)
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <ThemeProvider theme={theme}>
          <AppBarWithLogo />

          {/* Check if there are messages in the conversation */}
          {conversation.length > 0 ? (
            <div className="overlay">

              {/* container for chat messages */}
              <div className="chat-container" ref={chatContainerRef}>

                {/* Render each message in the conversation */}
                {conversation.map((message, index) => (
                  <MessageBubbleComponent
                    key={index}
                    role={message.role} // role can be 'user' or 'assistant' 
                    content={message.content} // message text
                    loading={false}
                  />
                ))}

                {/* Show a loading bubble if the assistant is still responding */}
                {loading && <MessageBubbleComponent
                  role={"assistant"}
                  content={""}
                  loading={loading}
                />}
              </div>
              {/* Chat input component for user messages */}
              <div className="home-page-chat-container">
                <ChatComponent inputText={inputText} setInputText={setInputText} handleSend={handleSend} />
              </div>
              {/* Buttons for navigation and actions */}
              <div className="homeButtonsContainer">
                <DefaultButton text="Launch Pad" hexColor="#625B71" to="/LaunchPad" />
                <DefaultButton text="Email Generator" hexColor="#625B71" onClick={() => setOpenEmailModal(true)} />
                <DefaultButton text="IEP Data" hexColor="#625B71" onClick={() => setOpenIEPModal(true) } />
                <DefaultButton
                  text="Discover Prompt Ideas"
                  hexColor="#625B71"
                  onClick={generatePrompt}
                />
              </div>
            </div>
          ) : (

            // Render this layout if there are no messages
            <div className="mainPageLayout">
              <div className="mainPageTextLayout">
                <p className="mainPageTitleFont">Welcome to EduNova</p>
                <p className="mainPageParagraphFont">EduNova provides a modern solution to educators in understanding and managing the classroom.</p>
              </div>


              <div className="home-page-chat-container">
                {/* Chat input for initial user messages */}
                <ChatComponent inputText={inputText} setInputText={setInputText} handleSend={handleSend}/>
              </div>


              {/* Buttons for navigation and actions */}
              <div className="homeButtonsContainer">
                <DefaultButton text="Launch Pad" hexColor="#625B71" to="/LaunchPad" />
                <DefaultButton text="Email Generator" hexColor="#625B71" onClick={() => setOpenEmailModal(true)} />
                <DefaultButton text="IEP Data" hexColor="#625B71" onClick={() => setOpenIEPModal(true)}/>
                <DefaultButton
                  text="Discover Prompt Ideas"
                  hexColor="#625B71"
                  onClick={generatePrompt}
                />
              </div>
            </div>
          )}
        </ThemeProvider>
        <HelpButton 
          helpText="Hey there! The Cosmo on this page is able to help you with tasks such as lesson planning, learning new teaching methods, exploring educational theories, or just tips and tricks to help you along the way. Head over to the Launch Pad if you want to chat more specifically about the students in your class."
        />
      </header>
      <EmailGeneratorModal open={openEmailModal} onClose={() => setOpenEmailModal(false)} />
      <IEPDataModal open={openIEPModal} onClose={ () => setOpenIEPModal(false)} />
    </div>
  );
}

export default Home;
