import React from 'react';
import '../components/styles/MessageBubbleComponent.css';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import DefaultButton from './DefaultButton';
import ReactLoading from "react-loading";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { apiPost } from '../api';

/**
 * MessageBubbleComponent
 * 
 * This component represents an individual message bubble in the chat.
 * It supports both user and assistant messages, with additional functionality
 * for copying text and text-to-speech.
 * 
 * @param {Object} props - Component properties
 * @param {string} props.role - The sender of the message ("user" or "assistant")
 * @param {Array} props.content - The content of the message (array of text parts)
 * @param {boolean} props.loading - Indicates if the message is still loading
 * 
 * @returns {JSX.Element} The MessageBubbleComponent
 */
const MessageBubbleComponent = ({ role, content, loading }) => {

  async function speak(message) {
    try {
      const audioBlob = await apiPost("/speech", { text: message });
      const audioUrl = URL.createObjectURL(audioBlob);
      const audio = new Audio(audioUrl);
      audio.play();
    } catch (error) {
      console.error("❌ Error fetching or playing speech:", error);
    }
  }

  const handleCopy = (message) => {
    navigator.clipboard.writeText(message).then(() => {
      alert("Message copied to clipboard!");
    }).catch((error) => {
      console.error("❌ Failed to copy text:", error);
    });
  };

  return (
    <div className={`message-bubble ${role === "user" ? "user-message" : "assistant-message"}`}>
      <div className="header-container" style={{ display: 'flex', alignItems: 'center' }}>

        {/* Display the sender's label, 'You' for the user or 'Cosmo' for the assistant */}
        <strong>{role === "user" ? "You" : "Cosmo"}:</strong>

        <div className="buttonsContainer"> 
        {/* Copy button */}
        <DefaultButton
          text=""
          size="small"
          sx={{
            backgroundColor: 'transparent',
            padding: '4px 6px',
            boxShadow: 'none',
            width: '24px',
            height: '24px',
            minWidth: '0',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          icon={<ContentCopyIcon sx={{ color: role === "user" ? '#232030' : '#232030', fontSize: '20px' }} />}
          onClick={() => handleCopy(content.map(part => part.text).join(" ").replace(/<[^>]*>/g, ""))}
        />

        <DefaultButton
          text=""
          size="small"
          sx={{
            backgroundColor: 'transparent',
            padding: '4px 6px',
            marginLeft: 'auto',
            boxShadow: 'none',
            width: '24px',
            height: '24px',
            minWidth: '0',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}

          // text to speech 
          icon={<VolumeUpIcon sx={{ color: role === "user" ? '#232030' : '#232030', fontSize: '20px' }} />}
          onClick={() => speak(content[0].text.replace(/<[^>]*>/g, ""))}
        />
        </div>
      </div>
      {loading ? <ReactLoading type="bubbles" color="black" /> :
        <pre className="message-content">

          {/* Message content starts on a new line */}
          {/* Map over the content array and render each part */}
          {content.map((part, idx) => (
            <div key={idx} dangerouslySetInnerHTML={{__html: part.text.replace(/\n/g, "").trim()}}/>
          ))}
        </pre>}
    </div>
  );
};

export default MessageBubbleComponent;
