/**
 * LaunchPad component is the main dashboard for displaying students' performance.
 * The dashboard divides students into three groups (Top, Middle, and Bottom) based on their performance 
 * and provides an interactive way to view and analyze these students.
 * It also provides a visual representation with a moon and rocket SVG and a help button for the user.
 * 
 * @component
 * @example
 * return <LaunchPad />;
 * 
 * @param {none}
 * 
 * @state {Object} studentsData - Contains the data for the top, middle, and bottom students.
 * @state {Array} studentsData.top_3 - Array of top-performing students.
 * @state {Array} studentsData.middle_3 - Array of middle-performing students.
 * @state {Array} studentsData.bottom_3 - Array of bottom-performing students.
 * 
 * @method fetchStudentsData - Fetches student data from the API and divides it into top, middle, and bottom students.
 * @returns {Promise<void>} - Updates the `studentsData` state with the fetched data.
 * 
 */
import React, {useState, useEffect } from 'react';
import {apiGet} from '../api';
import AppBarWithLogo from '../components/AppBarWithLogo';
import TopXStudents from '../components/TopXStudents';
import "../pages/styles/LaunchPad.css";
import moonForeground from '../assets/moon_foreground.svg';
import rocketship from '../assets/rocketship.svg';
import HelpButton from '../components/HelpButton';

function LaunchPad() {
  const [studentsData, setStudentsData] = useState({ top: [], median: [], bottom: [] });

  const fetchStudentsData = async () => {
    try {
      const data = await apiGet('/shs_data');  // make sure this hits the correct endpoint
      console.log(data);

      const totalStudents = data.length;
      const third = Math.floor(totalStudents / 3);
      
      setStudentsData({
        top_3: data.slice(0, third),                 // First third
        middle_3: data.slice(third, 2 * third),      // Second third
        bottom_3: data.slice(2 * third),             // Last third
      });
    } catch (error) {
      console.error('Error fetching data:', error);
    } 
  };

  useEffect(() => {
    fetchStudentsData();
  }, []);


  return (
    <div className="LaunchPad">
      <AppBarWithLogo />
      {/* SVG Container for Moon & Rocket */}
      <div className="svg-container">
        <img src={moonForeground} alt="Moon Foreground" className="moon-svg" />
        <img src={rocketship} alt="Rocket Ship" className="rocketship-svg" />
      </div>

      {/* Top, Middle, and Bottom Boxes */}
      <TopXStudents className = "top-students" headerText ="In Orbit" studentsData={studentsData.top_3}/>
      <TopXStudents className = "middle-students" headerText = "Taking Off" studentsData={studentsData.middle_3}/>
      <TopXStudents className = "bottom-students" headerText = "Countdown" studentsData={studentsData.bottom_3}/>
      <HelpButton className = "help-button" helpText="Welcome to the Launch Pad! Here you can take a look at where your students are generally performing - Countdown, Taking Off, and In Orbit! Clicking on a student will bring up the Health Dashboard, where you can see some summary statistics to help you better understand how they are performing. From here, you can generate reports for parent-teacher conferences and chat with Cosmo about that specific student!"/>
    </div>
  );
};

export default LaunchPad;
