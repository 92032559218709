import React, { useState } from "react";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { IconButton, Popper, Typography, Paper } from "@mui/material";

/**
 * HelpButton Component
 * 
 * This component provides a floating help button that displays a tooltip-like popper 
 * when hovered over. The help text can be customized via props.
 * 
 * @param {Object} props - Component properties
 * @param {string} props.helpText - The text displayed inside the help popper
 * 
 * @returns {JSX.Element} The HelpButton component
 */
const HelpButton = ({ helpText }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMouseEnter = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMouseLeave = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      {/* Help icon button positioned at the bottom-right corner */}
      <IconButton
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        aria-label="Help Information Button"
        sx={{
            position: "fixed",
            bottom: 20, // Adjusts the vertical position
            right: 20, // Adjusts the horizontal position
            color: "white",
            backgroundColor: "black", // Makes it stand out
            "&:hover": { backgroundColor: "gray" }, // Hover effect
          }} // Makes the button white
      >
        <HelpOutlineOutlinedIcon />
      </IconButton>

      {/* Popper component that appears above the button */}
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="top"
        disablePortal={false}
        sx={{
          zIndex: 9999,
          pr: 2,
        }}
        modifiers={[
          {
            name: "offset",
            options: {
              offset: [8, 8], // Adjusts positioning
            },
          },
        ]}
      >
        {open && (
          <Paper
            sx={{
              width: 300, // Fixed width
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "white",
              boxShadow: 3,
              borderRadius: 2, // Optional for rounded corners
              overflow: "hidden", // Ensures text doesn't overflow
              textAlign: "left", // Center align text
            }}
          >
            <Typography variant="body2" color="black" sx={{ padding: 1, wordWrap: "break-word" }}>
              {helpText}
            </Typography>
          </Paper>
        )}
      </Popper>
    </>
  );
};

export default HelpButton;
