import React from 'react';
import '../components/styles/ReportBubbleComponent.css';

/**
 * ReportBubbleComponent
 * 
 * This component renders a styled text bubble containing the provided HTML-formatted text.
 * 
 * @param {Object} props - Component properties
 * @param {string} props.text - The HTML-formatted text content to be displayed inside the bubble
 * 
 * @returns {JSX.Element} The ReportBubbleComponent
 */
const ReportBubbleComponent = ({ text }) => {
    return (
      <div className="text-bubble">
        <div dangerouslySetInnerHTML={{ __html: text }} />
      </div>
    );
  };
  
  export default ReportBubbleComponent;
