import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogActions } from '@mui/material';
import DefaultButton from '../components/DefaultButton';
import StudentChatModal from '../modals/StudentChatModal';
import ParentTeacherModal from '../modals/ParentTeacherModal'
import PieChartComponent from '../components/PieChart';
import '../modals/styles/StudentHealthSummaryModal.css';

/**
 * StudentHealthSummaryModal component displays a detailed health and performance summary of a student.
 * It includes their behavior breakdown, grades, assignments, and other relevant metrics.
 * The component also provides options to generate reports and interact with a chat assistant.
 * 
 * @component
 * @example
 * return (
 *   <StudentHealthSummaryModal 
 *     open={isOpen} 
 *     onClose={handleClose} 
 *     student={studentData} 
 *   />
 * );
 * 
 * @param {boolean} open - Determines whether the modal is open or closed.
 * @param {function} onClose - Callback function to close the modal.
 * @param {Object} student - The student data to display in the modal.
 */
const StudentHealthSummaryModal = ({ open, onClose, student }) => {
    const [isChatOpen, setIsChatOpen] = useState(false); // Track if chat modal is open
    const [isReportModalOpen, setIsReportModalOpen] = useState(false); // Track if report modal is open
    const [reportGenerated, setReportGenerated] = useState(false); // Track if the report has been generated

    const overallCourseGrade = student.OVERALL_COURSE_GRADE.toFixed(2);
    const Points = student.TOTALPOINTS + "/" + student.MAXPOINTS;

    const data = [
        { label: "Attendance Behavior Relationship", value: student.ATTENDANCE_BEHAVIOR_RELATIONSHIP },
        { label: "Behavior Assignment Correlation", value: student.BEHAVIOR_ASSIGNMENT_CORRELATION },
        { label: "Most Common Behavior", value: student.MOST_COMMON_BEHAVIOR },
        { label: "Overall Course Grade", value: overallCourseGrade },
        { label: "Points", value: Points },
        { label: "Missing Assignments", value: student.MISSING_ASSIGNMENTS },
        { label: "Tardies", value: student.TARDIES }
    ];

    const pieChartData = [
        { id: 0, value: student.Negative || 0, label: "Negative" },
        { id: 1, value: student.Positive || 0, label: "Positive" },
        { id: 2, value: student.Neutral || 0, label: "Neutral" }
    ];

    const half = Math.ceil(data.length / 2 - 1);
    const firstHalf = data.slice(0, half);
    const secondHalf = data.slice(half);

    useEffect(() => {
        const hasGeneratedReport = sessionStorage.getItem('reportGenerated');
        if (hasGeneratedReport === 'true') {
            setReportGenerated(true);
        } else {
            setReportGenerated(false);  // Ensure it can be re-generated if needed.
        }
    }, []);

    // Trigger report generation on page load only if not already done
    useEffect(() => {
        if (student && !reportGenerated) {
            setReportGenerated(true);
            sessionStorage.setItem('reportGenerated', 'true'); // Persist the state across page reloads
        }
    }, [student, reportGenerated]);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth
            sx={{
                '& .MuiDialog-paper': {
                    borderRadius: '20px',
                    padding: '16px',
                    backgroundColor: '#E7E0EC',
                    textAlign: 'center',
                    overflow: 'hidden',
                    scrollbar: 'none',
                },
                '& .MuiDialogContent-root': {
                    overflow: 'hidden' 
            } 
            }}
        >
            <StudentChatModal open={isChatOpen} onClose={() => setIsChatOpen(false)} student={student} />
            <ParentTeacherModal
                open={isReportModalOpen}
                onClose={() => setIsReportModalOpen(false)}
                student={student}
                generateOnLoad={reportGenerated}
            />
            <>
                <DialogTitle
                    sx={{ textAlign: 'left', paddingLeft: '12px', fontWeight: 'bold', fontSize: '1.5rem' }}>
                    {student['FIRSTNAME'] + " " + student['LASTNAME']}</DialogTitle>

                <div className="modal-body">

                    <div className="top-container">
                        <div className="left-container">
                            <div className="rectangle">
                                {firstHalf.map((item, index) => (
                                    <p key={index} className="rectangle-text">
                                        <strong>{item.label}:</strong> {item.value}
                                    </p>
                                ))}
                            </div>

                            <div className="rectangle">
                                {secondHalf.map((item, index) => (
                                    <p key={index} className="rectangle-text">
                                        <strong>{item.label}:</strong> {item.value}
                                    </p>
                                ))}
                            </div>
                        </div>

                        <div className="right-container">
                            <PieChartComponent data={pieChartData} title = "Behavior Breakdown" />     
                        </div>   
                    </div>

                    <div className="bottom-container">

                        <div className="best-and-worst-container">
                            <div className="rectangle">
                                <strong>Best Assignments</strong>
                                <ul>
                                    {student.BEST_ASSIGNMENTS
                                        ? student.BEST_ASSIGNMENTS.split(",").map((assignment, index) => (
                                            <li key={index} className="rectangle-text">{assignment.trim()}</li>
                                        ))
                                        : <p className="rectangle-text">No best assignments available</p>
                                    }
                                </ul>
                            </div>        
                            <div className="rectangle"> 
                                <strong>Worst Assignments</strong>
                                <ul>
                                    {student.WORST_ASSIGNMENTS
                                        ? student.WORST_ASSIGNMENTS.split(",").map((assignment, index) => (
                                            <li key={index} className="rectangle-text">{assignment.trim()}</li>
                                        ))
                                        : <p className="rectangle-text">No worst assignments available</p>
                                    }
                                </ul>
                            </div>
                        </div>

                    <div className="rectangle">
                            <p className="rectangle-text">
                                <strong>Grade Influencing Factor:</strong> {student.GRADE_INFLUENCING_FACTOR}
                            </p>
                    </div>
                </div>
                </div>
                <DialogActions>
                    <DefaultButton
                        text={`Ask Cosmo`}
                        onClick={() => setIsChatOpen(true)}
                        variant="contained"
                        hexColor="#625B71"
                    />
                    <DefaultButton
                        text={`Generate Report`}
                        onClick={() => setIsReportModalOpen(true)}
                        variant="contained"
                        hexColor="#625B71"
                    />
                </DialogActions>
            </>

        </Dialog>

    );
};

export default StudentHealthSummaryModal;

