import React from 'react';
import { Link } from 'react-router-dom';  // Import Link
import Button from '@mui/material/Button';

/**
 * DefaultButton Component
 * 
 * A reusable button component that supports customizable styles, an optional icon, 
 * and can function as either a standard button or a navigation link.
 * 
 * @param {Object} props - Component properties
 * @param {string} [props.text='Button'] - The text displayed inside the button
 * @param {string} [props.variant='contained'] - The button variant (e.g., 'contained', 'outlined')
 * @param {string} [props.color='primary'] - The default MUI color of the button
 * @param {string} [props.hexColor] - Custom background color (overrides MUI color)
 * @param {string} [props.textColor] - Custom text color
 * @param {string} [props.hoverShadow='0px 4px 20px rgba(223, 174, 41, 0.79)'] - Box shadow on hover
 * @param {string} [props.size='medium'] - The size of the button ('small', 'medium', 'large')
 * @param {Function} [props.onClick] - Function triggered when button is clicked
 * @param {Object} [props.sx={}] - Additional styling options
 * @param {string} [props.borderRadius='20px'] - Border radius for rounded corners
 * @param {JSX.Element} [props.icon] - Optional icon component inside the button
 * @param {string} [props.to] - If provided, renders the button as a navigation link
 * 
 * @returns {JSX.Element} The DefaultButton component
 */
const DefaultButton = ({
    text = 'Button',
    variant = 'contained',
    color = 'primary',
    hexColor,
    textColor, 
    hoverShadow = '0px 4px 20px rgba(223, 174, 41, 0.79)',
    size = 'medium',
    onClick,
    sx = {},
    borderRadius = '20px',
    icon,
    to,  // Accept 'to' prop
}) => {
    return (
        <Button
            variant={variant}
            color={hexColor ? undefined : color}
            size={size}
            onClick={onClick}
            sx={{
                borderRadius,
                textTransform: 'none',
                boxShadow: 'none',
                ...(hexColor && { backgroundColor: hexColor, color: '#fff' }),
                ...(textColor && { color: textColor }),
                '&:hover': { boxShadow: hoverShadow },
                ...sx,
            }}
            aria-label={text}
        >

            {/* Render a Link component if 'to' is provided, otherwise render text/icon */}
            {to ? (
                <Link to={to} style={{ 
                    textDecoration: 'none', 
                    color: 'inherit' }}> 

                    {/* Use Link */}
                    {icon ? icon : text}  {/* Display icon if provided, otherwise text */}
                </Link>
            ) : (
                icon ? icon : text // Show icon or fallback to text if no 'to' provided
            )}
        </Button>
    );
};

export default DefaultButton;
