import React, { useRef, useEffect, useState } from 'react';
import { TextField, IconButton, Box } from "@mui/material";
import { RocketLaunch } from '@mui/icons-material';
import MicIcon from "@mui/icons-material/Mic";
import '../components/styles/ChatComponent.css';

/**
 * ChatComponent
 * 
 * This component provides a chat input field where users can type messages or use speech-to-text input.
 * It includes a text field, a microphone button for voice input, and a send button.
 * 
 * @param {Object} props - Component properties
 * @param {string} props.inputText - Current text in the input field
 * @param {Function} props.setInputText - Function to update the input text state
 * @param {Function} props.handleSend - Function to handle sending messages
 * @returns {JSX.Element} The ChatComponent
 */
const ChatComponent = ({ inputText, setInputText, handleSend }) => {
  const textAreaRef = useRef(null);
  const [recognition, setRecognition] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [error, setError] = useState(""); // Error state to handle speech recognition issues

  useEffect(() => {
    const { SpeechRecognition, webkitSpeechRecognition } = window;

    if (SpeechRecognition || webkitSpeechRecognition) {
      const recognitionInstance = new (SpeechRecognition || webkitSpeechRecognition)();
      recognitionInstance.continuous = true;
      recognitionInstance.interimResults = true;

      recognitionInstance.onresult = (event) => {
        let transcript = '';
        for (let i = event.resultIndex; i < event.results.length; i++) {
          transcript += event.results[i][0].transcript;
        }
        setInputText(transcript); // Update input text with speech-to-text result
      };

      recognitionInstance.onerror = (event) => {
        console.error("Speech recognition error:", event.error);
        if (event.error === 'no-speech') {
          setError("No speech detected. Please try again.");
        } else if (event.error === 'audio-capture') {
          setError("No microphone detected. Please check your microphone.");
        } else {
          setError("Speech recognition error. Please try again.");
        }
      };

      setRecognition(recognitionInstance); // Save recognition instance to state
    } else {
      console.log("SpeechRecognition API is not supported in this browser.");
    }
  }, [setInputText]);

  useEffect(() => {
    const textArea = textAreaRef.current;
    if (textArea) {
      textArea.style.height = "15px"; 
      textArea.style.height = `${textArea.scrollHeight}px`; 
    }
  }, [inputText]);

  // Start recording speech
  const startRecording = () => {
    if (recognition) {
      recognition.start();
      setIsRecording(true);
      setError("");  // Reset error state when recording starts
    }
  };

  // Stop recording speech
  const stopRecording = () => {
    if (recognition) {
      recognition.stop();
      setIsRecording(false);
    }
  };

  // Toggle the mic button
  const micButtonHandler = () => {
    if (isRecording) {
      stopRecording();
    } else {
      startRecording();
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      if (e.shiftKey) {
        e.preventDefault();
        setInputText((prev) => prev + "\n");
      } else {
        e.preventDefault();
        if (inputText.trim()) {
          handleSend();
        }
      }
    }
  }


  return (
    <Box className="chat-box">

      {/* Text input field for typing messages */}
      <TextField
        inputRef={textAreaRef}
        multiline
        variant="standard"
        fullWidth
        value={inputText}
        onChange={(e) => setInputText(e.target.value)}  // Allow user to edit text
        placeholder="Type your message here..."
        size="medium"
        className="chat-input"
        aria-label="Chat input field"
        onKeyDown={handleKeyDown}
        sx={{
          minHeight: "20px",
          maxHeight: "150px",
          overflowY: "auto",
          resize: "none",
          whiteSpace: "pre-wrap",
          paddingLeft: "10px", 
          "& .MuiInputBase-input": {
            lineHeight: "1.5",
          },
        }}
      />

      {/* Display the error message if present */}
      {error && (
        <Box sx={{ color: 'red', fontSize: '12px', marginTop: '8px' }}>
          {error}  
        </Box>
      )}

      {/* Microphone button for voice input */ }
      <IconButton
        onClick={micButtonHandler}
        color="primary"
        className="mic-button"
        aria-label={isRecording ? "Stop recording" : "Start recording"}
      >
        <MicIcon color={isRecording ? "error" : "primary"} />
      
      {/* Send button to submit the message */}
      </IconButton>
      <IconButton
        onClick={handleSend}
        color="primary"
        disabled={!inputText.trim()}  // Disable send button if input is empty or nothing recorded
        className="send-button"
        aria-label="Send message"
      >
        <RocketLaunch style={{ transform: "rotate(45deg)" }} />
      </IconButton>
    </Box>
  );
};

export default ChatComponent;
