import React, { useState } from 'react';
import DefaultButton from '../components/DefaultButton';
import StudentHealthSummaryModal from '../modals/StudentHealthSummaryModal';
import '../components/styles/IndividualStudent.css';

/**
 * Individual Student Component
 * 
 * This component displays a button containing a student's name and overall course grade.
 * When the button is clicked, a modal opens displaying the student's health summary. 
 * 
 * @param {Object} props - Component properties
 * @param {Object} props.student - Student information including first name, last name, and overall course grade 
 * @returns {JSX.Element} The IndividualStudent component 
 */
const IndividualStudent = ({ student }) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const overallGrade = Math.round(student['OVERALL_COURSE_GRADE']) + "%"; // Round and add %

    return (
        <div className="individual-student">
            <DefaultButton
                text={
                    <span style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%"
                    }}>
                        {/* Displaying student's first and last name */}
                        <span style={{ textAlign: "left", flex: 1 }}>
                            {student['FIRSTNAME']} {student['LASTNAME']}
                        </span>
                        {/* Displaying the rounded overall course grade */}
                        <span style={{ textAlign: "right", minWidth: "40px" }}>
                            {overallGrade}
                        </span>
                    </span>
                }
                variant="contained"
                hexColor="#EADDFF"
                textColor="#000000"
                size="small"
                onClick={handleOpen}
                borderRadius="10px"
                sx={{
                    width: '100%',
                    padding: '6px 12px', 
                    fontSize: '14px',
                    fontWeight: 'bold',
                    textTransform: 'none',
                    display: 'flex', 
                    justifyContent: 'space-between',
                }}
            />

            {/* Modal component to show student health summary*/}
            <StudentHealthSummaryModal open={open} onClose={handleClose} student={student} />
        </div>
    );
};

export default IndividualStudent;
