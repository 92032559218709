const API_BASE_URL = 'https://edunova.us-west-2.elasticbeanstalk.com/'; // Replace with your actual API base URL
//const API_BASE_URL = 'http://127.0.0.1:8000'

const handleResponse = async (response) => {
    // Handles any response from the API

    if (!response.ok) {
        try {
            // Try to parse the error as JSON
            const error = await response.json();
            throw new Error(error.message || 'Something went wrong');
        } catch (jsonError) {
            // If response is not JSON (e.g., MP3), throw a generic errior
            throw new Error('API request failed with status ' + response.status);
        }
    }

    // Check if response is JSON or binary (MP3)
    const contentType = response.headers.get("content-type");

    if (contentType && contentType.includes("application/json")) {
        return response.json(); // ✅ Return JSON if response is JSON
    } else {
        return response.blob(); // ✅ Return binary data (MP3) if response is not JSON
    }
};

const getHeaders = (isJson = true) => {
    // dynamically get the headers that api calls need
    const headers = {};
    if (isJson) headers['Content-Type'] = 'application/json';
    // Add additional headers like authorization here if needed
    return headers;
};

export const apiGet = async (endpoint) => {
    // default method for a GET api call
    const response = await fetch(`${API_BASE_URL}${endpoint}`, {
        method: 'GET',
        headers: getHeaders(),
    });
    return handleResponse(response);
};

export const apiPost = async (endpoint, data) => {
    // default method for a POST api call
    const response = await fetch(`${API_BASE_URL}${endpoint}`, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(data),
    });
    return handleResponse(response);
};

export const apiPut = async (endpoint, data) => {
    // default method for a PUT api call
    const response = await fetch(`${API_BASE_URL}${endpoint}`, {
        method: 'PUT',
        headers: getHeaders(),
        body: JSON.stringify(data),
    });
    return handleResponse(response);
};

export const apiDelete = async (endpoint) => {
    // default method for a DELETE api call
    const response = await fetch(`${API_BASE_URL}${endpoint}`, {
        method: 'DELETE',
        headers: getHeaders(),
    });
    return handleResponse(response);
};
