import React, { useState, useEffect, useRef } from "react";
import { Dialog, DialogContent, IconButton, DialogTitle } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import './styles/EmailGeneratorModal.css';
import MessageBubbleComponent from "../components/MessageBubbleComponent";
import ChatComponent from "../components/ChatComponent";
import { apiPost } from "../api";

/**
 * StudentChatModal Component
 * 
 * This modal allows the user to chat with an assistant about a student.
 * It displays a conversation and allows sending and receiving messages.
 * 
 * @param {Object} props - Component properties
 * @param {boolean} props.open - Whether the modal is open or not
 * @param {function} props.onClose - Function to close the modal
 * @param {Object} props.student - The student whose information is being discussed
 * 
 * @returns {JSX.Element} The StudentChatModal component
 */
const StudentChatModal = ({ open, onClose, student = {} }) => {
    // feel like this can be pulled out of StudentChatModal and into ChatComponent somehow
    const [conversation, setConversation] = useState([]);
    const [inputText, setInputText] = useState("");
    const [loading, setLoading] = useState(false);
    const chatContainerRef = useRef(null);

    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTo({
                top: chatContainerRef.current.scrollHeight,
                behavior: "smooth",
            });
        }
    }, [conversation])

    const handleSend = async () => {
        if (inputText.trim()) {
            try {
                const updatedConversation = [...conversation, { role: "user", content: [{ text: inputText }] }];
                setConversation(updatedConversation);
                setLoading(true);
                setInputText('');
                const response = await apiPost('/single_student_chat', { content: updatedConversation, student_id: student['STUDENTID'] });
                setConversation(conversation => [...conversation, { role: "assistant", content: [{ text: response['output']['message']['content'][0]['text'] }] }]);
                setLoading(false);
            } catch (error) {
                console.error("Error sending message:", error);
            }
        }
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md" sx={{
            '& .MuiPaper-root': { borderRadius: '20px' }, // Adjust the radius as needed
        }}>
            <DialogTitle>
                <div className="chat-modal-header">
                    {student && <h3>{student['FIRSTNAME'] + " " + student['LASTNAME']}</h3>}
                    <IconButton onClick={onClose} className="close-button" disableFocusRipple disableRipple>
                        <CloseIcon />
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
                <div className="student-chat-container" ref={chatContainerRef}>
                    <MessageBubbleComponent role="assistant" content={[{ text: "Hey there! What questions about " + student['FIRSTNAME'] + " can I help answer for you?" }]} loading={false} />
                    {conversation.length >= 1 && conversation.map((message, index) => (
                        <MessageBubbleComponent
                            key={index}
                            role={message.role}
                            content={message.content}
                            loading={false}
                        />
                    ))}
                    {loading && <MessageBubbleComponent role="assistant" content="" loading={true} />}
                </div>
                <ChatComponent inputText={inputText} setInputText={setInputText} handleSend={handleSend} />
            </DialogContent>
        </Dialog>
    );
};

export default StudentChatModal;