import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

/**
 * AppBarWithLogo Component
 * 
 * This component renders a fixed AppBar with a logo that redirects to the homepage when clicked.
 * 
 * @returns {JSX.Element} The AppBarWithLogo component
 */
function AppBarWithLogo() {
  /**
   * Handles the click event on the logo text.
   * Redirects the user to the homepage ('/').
   */
  const handleClick = () => {
    window.location.href = '/';  // This changes the URL to the homepage
  };

  return (
    <AppBar position="fixed" sx={{ backgroundColor: '#232030' }}> 
      <Toolbar>
        <Box display="flex" alignItems="center">
          <Typography variant="h6" color="inherit" onClick={handleClick} style={{ cursor: 'pointer' }}>
            EduNova
          </Typography>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default AppBarWithLogo;

