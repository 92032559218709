import { Routes, Route } from 'react-router-dom';
import '@fontsource/roboto';
import Home from './pages/Home';
import LaunchPad from './pages/LaunchPad';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/LaunchPad" element={<LaunchPad />} />
    </Routes>
  );
}

export default App;


