import React, { useState, useEffect } from "react";
import { Dialog, DialogContent, DialogTitle, IconButton, MenuItem, Select, FormControl, InputLabel } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { apiPost, apiGet } from "../api"; // Assuming you have apiGet and apiPost methods
import "./styles/IEPDataModal.css";

const IEPDataModal = ({ open, onClose }) => {
  const [selectedStudent, setSelectedStudent] = useState(""); // State for the selected student
  const [students, setStudents] = useState([]); // State to store the list of students
  const [iepData, setIEPData] = useState([]); // State to store IEP data from the API

  // Fetch students data from the API
  const fetchStudentsData = async () => {
    try {
      const data = await apiGet("/shs_data");
      console.log("Fetched Students Data:", data);
      setStudents(data);
    } catch (error) {
      console.error("Error fetching student data >:o:", error);
    }
  };

  // Fetch IEP Data based on selected student ID
  const fetchIEPData = async (studentId) => {
    try {
      const response = await apiPost('/iepdata', { student_id: studentId });
      console.log('Fetched IEP Data:', response);
      setIEPData(response); // Set the response data to the state
    } catch (error) {
      console.error('Error fetching IEP data >:o : ', error);
    }
  };

  // Handle the change in selected student and fetch IEP data
  const handleStudentChange = (event) => {
    const studentId = event.target.value;
    setSelectedStudent(studentId);
    if (studentId) {
      fetchIEPData(studentId); // Fetch IEP data when a student is selected
    } else {
      setIEPData(null); // Clear IEP data if no student is selected
    }
  };

  // Fetch the student data when the component is mounted
  useEffect(() => {
    fetchStudentsData();
  }, []);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md" sx={{
      '& .MuiPaper-root': { borderRadius: '20px' }, // Rounded corners
    }}>
      <DialogTitle>
        <div className="IEP-modal-header">
          <h3>IEP Data Aggregation</h3>

          {/* Dropdown for selecting a student */}
          <FormControl sx={{ minWidth: 120 }}>
            <InputLabel id="student-select-label">Student</InputLabel>
            <Select
              labelId="student-select-label"
              id="student-select"
              value={selectedStudent}
              label="Student"
              onChange={handleStudentChange}
              sx={{
                borderRadius: '12px',
                backgroundColor: '#fff',
                border: '1px solid #6A5ACD',
                '&:hover': { backgroundColor: '#f3f4f6' },
              }}
            >
              {/* Option to "deselect" student */}
              <MenuItem value="">None</MenuItem>

              {/* Dynamically generate menu items from the students list */}
              {students.map((student) => (
                <MenuItem key={student.STUDENTID} value={student.STUDENTID}>
                  {`${student.FIRSTNAME} ${student.LASTNAME}`} {/* Display student name */}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Close button */}
          <IconButton onClick={onClose} className="close-button" disableFocusRipple disableRipple>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>

      <DialogContent sx={{ display: "flex", flexDirection: "column", padding: "20px" }}>
  {/* Display IEP Data with proper checks */}

  {/* Check if iepData is available and has items */}
  {iepData && Array.isArray(iepData) && iepData.length > 0 ? (
    iepData.map((item, index) => (
      <div key={index} style={{ marginBottom: '20px', padding: '15px', border: '1px solid #ddd', borderRadius: '8px' }}>
        {/* Assignment Title */}
        <h4 style={{ fontSize: '18px', fontWeight: 'bold' }}>
          {item.ASSIGNMENT_TITLE}
        </h4>

        {/* Assignment Grade and Points */}
        <p style={{ fontSize: '16px', margin: '5px 0' }}>
          <strong>Grade:</strong> {item.ASSIGNMENT_GRADE}
        </p>

        {/* Behavior Category */}
        <p style={{ fontSize: '16px', margin: '5px 0' }}>
          <strong>Behavior Category:</strong> {item.BEHAVIOR_CATEGORY}
        </p>

        {/* Behavior Comment */}
        <p style={{ fontSize: '16px', margin: '5px 0' }}>
          <strong>Behavior Comment:</strong> {item.BEHAVIOR_COMMENT}
        </p>

        {/* Date of Assignment */}
        <p style={{ fontSize: '16px', margin: '5px 0' }}>
          <strong>Date:</strong> {item.DATE}
        </p>
      </div>
    ))
  ) : (
    // If no IEP data available or loading
    <p style={{ fontSize: '16px', color: '#999' }}>
      No IEP data available for the selected student.
    </p>
  )}
</DialogContent>
    </Dialog>
  );
};

export default IEPDataModal;
