import React from "react";
import { PieChart } from "@mui/x-charts/PieChart";
import { Typography } from "@mui/material";
import '../components/styles/PieChart.css';  // Import the CSS file

/**
 * PieChartComponent
 * 
 * This component renders a pie chart with a title.
 * It utilizes the MUI X PieChart for visualization.
 * 
 * @param {Object} props - Component properties
 * @param {Array} props.data - Data points for the pie chart (each element should contain a `value` and `label`)
 * @param {string} props.title - Title to display above the pie chart
 * 
 * @returns {JSX.Element} The PieChartComponent
 */
const PieChartComponent = ({ data = [], title = "Pie Chart Title" }) => {
    return (
        <div className="pie-chart-container">
          <Typography 
            variant="h6" 
            component="h3" 
            className="pie-chart-title"
          >
            {title}
          </Typography>
          
          <PieChart
            series={[{ data: data }]}
            width={450}
            height={290}
          />
        </div>
    );
};

export default PieChartComponent;
