import React, { useState, useEffect } from "react";
import { Dialog, DialogContent, IconButton, DialogTitle, CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import './styles/ParentTeacherModal.css';
import ReportBubbleComponent from '../components/ReportBubbleComponent.js';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DefaultButton from '../components/DefaultButton';
import DownloadIcon from '@mui/icons-material/Download';
import { apiPost } from "../api";
import { jsPDF } from "jspdf";

/**
 * ParentTeacherModal Component
 * 
 * This modal generates and displays a parent-teacher report for a student. It allows the user
 * to copy the report content or download it as a PDF.
 * 
 * @param {Object} props - Component properties
 * @param {boolean} props.open - Whether the modal is open
 * @param {function} props.onClose - Function to close the modal
 * @param {Object} props.student - The student whose report is being generated
 * @param {boolean} props.generateOnLoad - Flag to generate report on load
 * 
 * @returns {JSX.Element} The ParentTeacherModal component
 */
const ParentTeacherModal = ({ open, onClose, student = {}, generateOnLoad = false }) => {
    const [loading, setLoading] = useState(false);
    const [reportData, setReportData] = useState(''); // Store the report data here

    // Trigger the report generation when the modal opens or on page load (based on generateOnLoad)
    useEffect(() => {
        // Generates Parent Teacher Report
        const handleSend = async () => {
            setLoading(true);
            try {
                const response = await apiPost('/generate_pt_report', { student_id: student['STUDENTID'] })
                const message = response?.output?.message?.content[0]?.text;
                setReportData(message);
                setLoading(false);
            }
            catch (error) {
                console.error("Error generating prompt", error);
                setLoading(false); // Ensure loading stops on error
            }
        };

        if (open || generateOnLoad) {
            if (!reportData) {
                handleSend();
            }
        }
    }, [open, generateOnLoad, reportData, student]);

    const handleCopy = (message) => {
        navigator.clipboard.writeText(message).then(() => {
            alert("Message copied to clipboard!");
        }).catch((error) => {
            console.error("❌ Failed to copy text:", error);
        });
    };

    // Handle PDF download
    const handleDownloadPdf = (plainText) => {
        const doc = new jsPDF();

        // Set font type and size
        doc.setFont("helvetica");
        doc.setFontSize(10); // Adjust the font size as needed

        // Split the plain text into lines that fit the page width
        const pageWidth = doc.internal.pageSize.width; // Get the page width
        const margin = 10;
        const lineHeight = 6;
        const textLines = doc.splitTextToSize(plainText, pageWidth - 2 * margin);

        // Add the text line by line to the PDF
        let y = margin;
        textLines.forEach(line => {
            if (y + lineHeight > doc.internal.pageSize.height - margin) {
                doc.addPage(); // Add a new page if the content overflows
                y = margin; // Reset Y position for the new page
            }
            doc.text(line, margin, y); // Add the line at the current Y position
            y += lineHeight; // Increase Y position for the next line
        });

        // Save the PDF with the filename based on the student's name
        doc.save(`${student['FIRSTNAME']}_${student['LASTNAME']}_Report.pdf`);
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md" sx={{
            '& .MuiPaper-root': { borderRadius: '20px' }, // Adjust the radius as needed
        }}>
            <DialogTitle>
                <div className="chat-modal-header" style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    {/* Student's name */}
                    {student && <h3>{student['FIRSTNAME'] + " " + student['LASTNAME']}</h3>}

                    {/* Container for buttons */}
                    <div className="modal-buttons-container" style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                        {/* Copy Button */}
                        <DefaultButton
                            text=""
                            size="small"
                            sx={{
                                backgroundColor: 'transparent',
                                padding: '4px 6px',
                                boxShadow: 'none',
                                width: '24px',
                                height: '24px',
                                minWidth: '0',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                cursor: 'pointer',
                            }}
                            icon={<ContentCopyIcon sx={{ color: '#232030', fontSize: '20px' }} />}
                            onClick={() => handleCopy(reportData.replace(/<[^>]*>/g, ""))}
                        />
                        {/* PDF Download Button */}
                        <DefaultButton
                            text="Download PDF"
                            size="small"
                            sx={{
                                backgroundColor: 'transparent',
                                padding: '4px 6px',
                                boxShadow: 'none',
                                width: '24px',
                                height: '24px',
                                minWidth: '0',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                cursor: 'pointer',
                            }}
                            icon={<DownloadIcon sx={{ color: '#232030', fontSize: '20px' }} />}
                            onClick={() => handleDownloadPdf(reportData.replace(/<[^>]*>/g, ""))}
                        />
                        {/* Close Button */}
                        <IconButton onClick={onClose} className="close-button" disableFocusRipple disableRipple>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </div>
            </DialogTitle>
            <DialogContent className="modal-content">
                {loading ? (
                    // Show a loading spinner while waiting for the API response
                    <div className="loading-container">
                        <CircularProgress />
                        <p>Generating report...</p>
                    </div>
                ) : (
                    // Pass the response data to the ReportBubbleComponent once it's available
                    <ReportBubbleComponent text={reportData} />
                )}
            </DialogContent>
        </Dialog >
    );
};

export default ParentTeacherModal;
