import React from 'react';
import IndividualStudent from './IndividualStudent';  
import '../components/styles/TopXStudents.css';


/**
 * TopXStudents Component
 * 
 * This component displays a list of top students inside a styled container.
 * Each student is rendered using the IndividualStudent component.
 * 
 * @param {Object} props - Component properties
 * @param {string} props.className - Additional class names for custom styling (optional)
 * @param {string} props.headerText - The header text to display at the top
 * @param {Array} props.studentsData - Array of student objects containing student details
 * 
 * @returns {JSX.Element} The TopXStudents component
 */
const TopXStudents = ({ className = '', headerText = 'Default Header', studentsData = [] }) => {
  console.log(studentsData);
  return (
    <div className={`main-rectangle ${className}`}>
      <h3 className="topx-header">{headerText}</h3>
      <div className="students-list">
        {studentsData.map((student, index) => (
          <IndividualStudent key={index} student={student} />  // Pass each student to IndividualStudent
        ))}
      </div>
    </div>
  );
};

export default TopXStudents;
